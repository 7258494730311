import {
  FETCHING_STATUS,
  SET_MOSQUE_DETAILS,
  FETCHING_SAAS_PLAN_LIST,
  SAAS_PLAN_LIST_SUCCESS,
  SAAS_PLAN_LIST_FAILURE,
  FETCHING_MOSQUE_LIST,
  FETCHING_MOSQUE_LIST_SUCCESS,
  FETCHING_MOSQUE_LIST_FAILURE,
  FETCHING_MEMBER_LIST,
  FETCHING_MEMBER_LIST_SUCCESS,
  FETCHING_MEMBER_LIST_FAILURE,
  ADD_NEW_MOSQUE,
  ADD_NEW_MOSQUE_SUCCESS,
  ADD_NEW_MOSQUE_FAILURE,
  UPDATE_ADDED_NEW_FLAG,
  UPDATE_MOSQUE_FAILURE,
  UPDATE_MOSQUE_SUCCESS,
  UPDATE_MOSQUE,
  ADD_NEW_PLAN_FAILURE,
  ADD_NEW_PLAN_SUCCESS,
  ADD_NEW_PLAN,
  SET_PLAN_DETAILS,
  UPDATE_ADMIN,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILURE,
  UPDATE_PAGE,
  RESET_LINK,
  RESET_LINK_SUCCESS,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE,
  DASHBOARD_STATS_DATA,
  DASHBOARD_STATS_DATA_FAILURE,
  DASHBOARD_STATS_DATA_SUCCESS,
  FETCH_ADMIN_PROFILE_DETAIL,
  FETCH_ADMIN_PROFILE_SUCCESS,
  FETCH_ADMIN_PROFILE_FAILED,
  DELETE_PLAN_FAILURE,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILURE,
  UPDATE_MOSQUE_ADMIN_DETAILS,
  UPDATE_MOSQUE_ADMIN_DETAILS_SUCCESS,
  UPDATE_MOSQUE_ADMIN_DETAILS_FAILURE,
  UPDATE_ORG_DETAILS,
  UPDATE_ORG_DETAILS_SUCCESS,
  UPDATE_ORG_DETAILS_FAILURE,
  FETCH_ENTITY_FIN_REVENUE_DETAILS,
  FETCH_ENTITY_FIN_REVENUE_DETAILS_FAILURE,
  FETCH_ENTITY_FIN_REVENUE_DETAILS_SUCCESS,
  SET_ENTITY_FIN_REVENUE_DETAILS,
  FETCH_ORG_REVENUE_STATS_DETAILS,
  FETCH_ORG_REVENUE_STATS_DETAILS_SUCCESS,
  FETCH_ORG_REVENUE_STATS_DETAILS_FAILURE,
  FETCH_ORG_SUBS_COUNT_DETAILS,
  FETCH_ORG_SUBS_COUNT_DETAILS_SUCCESS,
  FETCH_ORG_SUBS_COUNT_DETAILS_FAILURE,
  SET_ACTIVE_USER_ID,
  ATTEMPT_TO_ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILED,
  SET_ADMIN,
  UPDATE_MEMBER_INFO,
  UPDATE_MEMBER_INFO_SUCCESS,
  UPDATE_MEMBER_INFO_FAILURE,
  UPDATE_PAGE_FOR_MEMBER_INFO_LIST,
  FETCHING_APPROVAL_LIST,
  FETCHING_APPROVAL_LIST_SUCCESS,
  FETCHING_APPROVAL_LIST_FAILURE,
  MOSQUE_APPROVAL_STATUS,
  LOAD_MEMBER_REMINDER_HISTORY,
  LOADING_MEMBER_REMINDER_HISTORY,
  LOADING_MEMBER_PAYMENT_HISTORY,
  LOAD_MEMBER_PAYMENT_HISTORY, MOSQUE_DECLINE_STATUS,
} from "./adminActionType";
import { callApiV2} from "../../util/apiCaller";
import { showNotification } from "../../util/Utility";
import { APP_TOKEN, USER_TYPE, USER_ID } from "../../constants/appEnums";
import jwt_decode from "jwt-decode";
import { AUTHENTICATED } from "../../constants/authEnums";
import { setAuthState, setUserType } from "../appAction";
import { useTranslation } from "react-i18next";
export const setAdmin = (user) => ({
  type: SET_ADMIN,
  user,
});
export const setActiveUserId = (id) => ({
  type: SET_ACTIVE_USER_ID,
  id,
});

export const setFetchingDetails = () => ({
  type: FETCHING_STATUS,
});

export const updatePage = (current) => ({
  type: UPDATE_PAGE,
  current,
});

export const updatePageForMemberInfoList = (current) => ({
  type: UPDATE_PAGE_FOR_MEMBER_INFO_LIST,
  current,
});

export const setMosqueDetails = (keyName, keyValue) => ({
  type: SET_MOSQUE_DETAILS,
  keyName,
  keyValue,
});

export const fetchSaasPlanList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_SAAS_PLAN_LIST,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `saas_subscriptions/list`,
      body: {
        filter: {},
        pageNum: 1,
        pageSize: 50,
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: SAAS_PLAN_LIST_SUCCESS,
        planList: res?.data?.saas_subscription_planList || [],
        planListCount: res?.data?.saas_subscription_planCount || 0,
        isFetching: false,
      });
    } else if (res?.status === "Error") {
      dispatch({
        type: SAAS_PLAN_LIST_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    showNotification("error", "Er is iets misgegaan..");
    dispatch({
      type: SAAS_PLAN_LIST_FAILURE,
      isFetching: false,
    });
  }
};

export const addNewMosque = (createdByPO,onboardEntityType) => async (dispatch, getState) => {
  console.log("OnboardType: ", onboardEntityType);
  try {
    dispatch({
      type: ADD_NEW_MOSQUE,
      isFetching: true,
    });
    let mosqueDetails = getState()?.adminReducer?.mosqueDetails;
    let updatedMosqueDetails = {
      ...mosqueDetails,
      created_by_PO: createdByPO ? true : false, // Update the created_by_PO property
      onboardEntityType: onboardEntityType, 
    };
      const res = await callApiV2({
        method: "post",
        endpoint: `onboarding/new-entity-signup`,
        body: updatedMosqueDetails ,
       isErrorSuppressed: true
      });
      if (res?.status === "Success") {
        dispatch({
          type: ADD_NEW_MOSQUE_SUCCESS,
          payload: res?.data,
          isFetching: false,
          isAddedNew: true,
        });
        showNotification("success", "Moskee succesvol toegevoegd.");
      } else {
        throw new Error(res?.data?.message || "Failed to add mosque.");
      }
    } catch (err) {
      showNotification("error", err.message || "Er is iets misgegaan..");
      dispatch({
        type: ADD_NEW_MOSQUE_FAILURE,
        isFetching: false,
      });
    }
  };

export const updateMosque = (id, body, suppressNext) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_MOSQUE,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `entity/${id}/update`,
      body: {
        entity: body,
      },
    });
    if (!suppressNext) {
      if (res?.status === "Success") {
        showNotification("success", "Moskee succesvol bijgewerkt.");
        dispatch(fetchMosqueList());
        dispatch({
          type: UPDATE_MOSQUE_SUCCESS,
          payload: res?.data,
          isFetching: false,
        });
        window.location.href = "/admin/mosques";
      } else {
        showNotification("error", res?.data?.message || "Er is iets misgegaan..");
        dispatch({
          type: UPDATE_MOSQUE_FAILURE,
          isFetching: false,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: UPDATE_MOSQUE_FAILURE,
      isFetching: false,
    });
  }
};

export const updateMosqueStatus = (id, body, suppressNext) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_MOSQUE,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `entity/${id}/update`,
      body: {
        entity: body,
      },
    });
    if (!suppressNext) {
      if (res?.status === "Success") {
        showNotification("success", "Moskee succesvol bijgewerkt.");
        dispatch(fetchMosqueList());
        dispatch({
          type: UPDATE_MOSQUE_SUCCESS,
          payload: res?.data,
          isFetching: false,
        });
        window.location.href = "/admin/mosques";
      } else {
        console.log(' error while updating mosque ', res.data);
        
        showNotification("error","Er is iets misgegaan..");
        dispatch({
          type: UPDATE_MOSQUE_FAILURE,
          isFetching: false,
        });
        window.location.href = "/admin/mosques";
      }
    }
  } catch (err) {
    dispatch({
      type: UPDATE_MOSQUE_FAILURE,
      isFetching: false,
    });
  }
};

export const updateAdminDetails = (id, details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ADMIN,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `admin/${id}/update`,
        body: {
          admin: details,
        },
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        showNotification("success", "Beheerdersgegevens succesvol bijgewerkt.");
        dispatch({
          type: UPDATE_ADMIN_SUCCESS,
          details: res?.data,
          isFetching: false,
        });
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: UPDATE_ADMIN_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_ADMIN_FAILURE,
        isFetching: false,
      });
    }
  };

export const updateAddedNewFlag = (status) => ({
  type: UPDATE_ADDED_NEW_FLAG,
  status,
});

export const fetchMosqueList = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_MOSQUE_LIST,
      isFetching: true,
    });
    let pageNum = getState()?.adminReducer?.mosque?.currentPage;
    let pageSize = getState()?.adminReducer?.mosque?.pageSize;
    const res = await callApiV2({
      method: "post",
      endpoint: `admin/organisations/list`,
      body: {
        filters: filters ?? {},
        pageNum: pageNum,
        pageSize: pageSize,
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCHING_MOSQUE_LIST_SUCCESS,
        list: res?.data?.list || [],
        count: res?.data?.count || 0,
        isFetching: false,
      });
    } else if (res?.status === "Error") {
      dispatch({
        type: FETCHING_MOSQUE_LIST_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    showNotification("error", "Er is iets misgegaan..");
    dispatch({
      type: FETCHING_MOSQUE_LIST_FAILURE,
      isFetching: false,
    });
  }
};

export const fetchMemberInfoList =(filters, pageNums) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCHING_MEMBER_LIST,
        isFetching: true,
      });
      if (!pageNums) {
        pageNums = getState()?.adminReducer?.memberInfo?.currentPage;
      }
      let pageSize = getState()?.adminReducer?.memberInfo?.pageSize;
      const res = await callApiV2({
        method: "post",
        endpoint: `admin/organisations/members-list`,
        body: {
          pageNum: pageNums,
          pageSize: pageSize,
          filters: filters ?? {},
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCHING_MEMBER_LIST_SUCCESS,
          list: res?.data?.list || [],
          count: res?.data?.count || 0,
          payments: res?.data?.payments || [],
          memberPayments: res?.data?.memberPayments || {},
          isFetching: false,
          pageNums,
          pageSize,
        });
      } else if (res?.status === "Error") {
        dispatch({
          type: FETCHING_MEMBER_LIST_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
      dispatch({
        type: FETCHING_MEMBER_LIST_FAILURE,
        isFetching: false,
      });
    }
  };

export const fetchMemberReminderHistory =({ member_id, pageNum, pageSize }) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_MEMBER_REMINDER_HISTORY,
        isFetching: true,
      });
      let query = `member/${member_id}/reminder-history`;
      const res = await callApiV2({
        method: "post",
        endpoint: `${query}`,
        body: {
          pageNum,
          pageSize,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: LOAD_MEMBER_REMINDER_HISTORY,
          isFetching: false,
          reminder_history: res.data.tasks,
          total_reminder_count: res.data.total_count,
        });
      } else {
        dispatch({
          type: LOADING_MEMBER_REMINDER_HISTORY,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: LOADING_MEMBER_REMINDER_HISTORY,
        isFetching: false,
      });
      showNotification("error", "Er is iets misgegaan..");
    }
  };

export const fetchMemberPaymentHistory =({ member_id, pageNum, pageSize }) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_MEMBER_PAYMENT_HISTORY,
        isFetching: true,
      });
      let query = `member/${member_id}/payment-history`;
      const res = await callApiV2({
        method: "post",
        endpoint: `${query}`,
        body: {
          pageNum,
          pageSize,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: LOAD_MEMBER_PAYMENT_HISTORY,
          isFetching: false,
          payment_history: res.data.payments,
          total_payment_count: res.data.total_count,
        });
      } else {
        dispatch({
          type: LOADING_MEMBER_PAYMENT_HISTORY,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: LOADING_MEMBER_PAYMENT_HISTORY,
        isFetching: false,
      });
      showNotification("error", "Er is iets misgegaan..");
    }
  };

export const updateMemberInfo = (id, details, isDisable = false) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_MEMBER_INFO,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `member_subscription/${id}/update`,
        body: {
          member_subscription: details,
        },
      });
      if (res?.status === "Success") {
        if (isDisable) {
          if (res?.data?.member_subscription?.is_active) {
            showNotification("success", "Member succesvol ingeschakeld.");
          } else {
            showNotification("success", "Member succesvol uitgeschakeld.");
          }
        }
        dispatch({
          type: UPDATE_MEMBER_INFO_SUCCESS,
          id,
          details,
          isFetching: false,
        });
      } else if (res?.status === "Error") {
        dispatch({
          type: UPDATE_MEMBER_INFO_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
      dispatch({
        type: UPDATE_MEMBER_INFO_FAILURE,
        isFetching: false,
      });
    }
  };

export const setPlanDetails = (keyName, keyValue) => ({
  type: SET_PLAN_DETAILS,
  keyName,
  keyValue,
});

export const addNewPlan = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_NEW_PLAN,
      isFetching: true,
    });
    let planDetails = getState()?.adminReducer?.plans?.plan_details;
    if (planDetails) {
      const res = await callApiV2({
        method: "post",
        endpoint: `saas_subscriptions/new`,
        body: {
          saas_subscription_plan: planDetails,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: ADD_NEW_PLAN_SUCCESS,
          payload: res?.data,
          isFetching: false,
          isAddedNew: true,
        });
        showNotification("success", "Plan succesvol toegevoegd.");
        dispatch(fetchSaasPlanList());
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: ADD_NEW_PLAN_FAILURE,
          isFetching: false,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_NEW_PLAN_FAILURE,
      isFetching: false,
    });
  }
};

export const forgetResetLink = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_LINK,
      isFetching: true,
    });
    let planDetails = getState()?.adminReducer?.plans?.plan_details;
    if (planDetails) {
      const res = await callApiV2({
        method: "post",
        endpoint: `admin/auth/forgot-password`,
        body: {
          admin: {
            contact_email: email,
          },
        },
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        dispatch({
          type: RESET_LINK_SUCCESS,
          isFetching: false,
          isResetLinkSend: true,
        });
        showNotification("success", "Reset link sent successfully.");
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: RESET_LINK,
          isFetching: false,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: RESET_LINK,
      isFetching: false,
    });
  }
};

export const ResetPassword = (resetLink, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PASSWORD_CHANGE,
        isFetching: true,
      });
      let planDetails = getState()?.adminReducer?.plans?.plan_details;
      if (planDetails) {
        const res = await callApiV2({
          method: "post",
          endpoint: `admin/auth/reset-password/${resetLink}`,
          body: {
            admin: {
              password: password,
            },
          },
        });
        if (res?.status === "Success") {
          dispatch({
            type: PASSWORD_CHANGE_SUCCESS,
            isFetching: false,
            isPasswordChange: true,
          });
          showNotification("success", "Wachtwoord succesvol gewijzigd.");
          // Logout and clear local storage
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("user_type");
          localStorage.removeItem("_expiredTime");
        } else {
          showNotification(
            "error",
            res?.data?.message || "Er is iets misgegaan.."
          );
          dispatch({
            type: PASSWORD_CHANGE,
            isFetching: false,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: PASSWORD_CHANGE,
        isFetching: false,
      });
    }
  };

export const updatePlan = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PLAN,
      isFetching: true,
    });
    let planDetails = getState()?.adminReducer?.plans?.plan_details;
    if (planDetails) {
      const res = await callApiV2({
        method: "post",
        endpoint: `saas_subscriptions/${id}/update`,
        body: {
          saas_subscription_plan: body,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: UPDATE_PLAN_SUCCESS,
          isFetching: false,
        });
        showNotification("success", "lidmaatschap succesvol bijgewerkt.");
        dispatch(fetchSaasPlanList());
        window.location.href = "/admin/planModification";
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: UPDATE_PLAN_FAILURE,
          isFetching: false,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PLAN_FAILURE,
      isFetching: false,
    });
  }
};

export const deletePlan = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_PLAN,
      isFetching: true,
    });
    let planDetails = getState()?.adminReducer?.plans?.plan_details;
    if (planDetails) {
      const res = await callApiV2({
        method: "post",
        endpoint: `saas_subscriptions/${id}/remove`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: DELETE_PLAN_SUCCESS,
          isFetching: false,
        });
        showNotification("success", "Plan succesvol verwijderd.");
        dispatch(fetchSaasPlanList());
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: DELETE_PLAN_FAILURE,
          isFetching: false,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELETE_PLAN_FAILURE,
      isFetching: false,
    });
  }
};

export const dashboardStatsData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DASHBOARD_STATS_DATA,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "get",
      endpoint: `admin/dashboard/home`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: DASHBOARD_STATS_DATA_SUCCESS,
        isFetching: false,
        details: res?.data,
      });
    } else {
      showNotification("error", res?.data?.message || "Er is iets misgegaan..");
      dispatch({
        type: DASHBOARD_STATS_DATA_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: DASHBOARD_STATS_DATA_FAILURE,
      isFetching: false,
    });
  }
};

export const fetchAdminProfileDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_ADMIN_PROFILE_DETAIL,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "get",
      endpoint: `admin/${id}`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCH_ADMIN_PROFILE_SUCCESS,
        isFetching: false,
        details: res?.data,
      });
    } else {
      showNotification("error", res?.data?.message || "Er is iets misgegaan..");
      dispatch({
        type: FETCH_ADMIN_PROFILE_FAILED,
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCH_ADMIN_PROFILE_FAILED,
      isFetching: false,
    });
  }
};

export const updateMosqueAdminDetails =
  (id, details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_MOSQUE_ADMIN_DETAILS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `agent/${id}/update`,
        body: {
          agent: details,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: UPDATE_MOSQUE_ADMIN_DETAILS_SUCCESS,
          isFetching: false,
        });
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: UPDATE_MOSQUE_ADMIN_DETAILS_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_MOSQUE_ADMIN_DETAILS_FAILURE,
        isFetching: false,
      });
    }
  };

export const updateOrgDetails = (id, details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_ORG_DETAILS,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `organisation/${id}/update`,
      body: {
        organisation: details,
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: UPDATE_ORG_DETAILS_SUCCESS,
        isFetching: false,
      });
    } else {
      showNotification("error", res?.data?.message || "Er is iets misgegaan..");
      dispatch({
        type: UPDATE_ORG_DETAILS_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: UPDATE_ORG_DETAILS_FAILURE,
      isFetching: false,
    });
  }
};

export const fetchEntityFinRevenue =
  (id, start_date, end_date) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ENTITY_FIN_REVENUE_DETAILS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "get",
        endpoint: `admin/dashboard/entity/${id}/entity-stats?start_date=${start_date}&end_date=${end_date}`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCH_ENTITY_FIN_REVENUE_DETAILS_SUCCESS,
          payload: res?.data,
          isFetching: false,
        });
      } else if (res?.status === "Error") {
        dispatch({
          type: FETCH_ENTITY_FIN_REVENUE_DETAILS_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
      dispatch({
        type: FETCH_ENTITY_FIN_REVENUE_DETAILS_FAILURE,
        isFetching: false,
      });
    }
  };

export const updateEntityFinRevenue = (val) => ({
  type: SET_ENTITY_FIN_REVENUE_DETAILS,
  val,
});

export const fetchOrgSubsCount =
  (start_date, end_date) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ORG_SUBS_COUNT_DETAILS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "get",
        endpoint: `admin/dashboard/organisation-subscription-count?start_date=${start_date}&end_date=${end_date}`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCH_ORG_SUBS_COUNT_DETAILS_SUCCESS,
          isFetching: false,
          details: res?.data,
        });
      } else {
        showNotification("error", "Er is iets misgegaan..");
        dispatch({
          type: FETCH_ORG_SUBS_COUNT_DETAILS_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ORG_SUBS_COUNT_DETAILS_FAILURE,
        isFetching: false,
      });
    }
  };

export const fetchOrgRevenueStats =
  (start_date, end_date) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ORG_REVENUE_STATS_DETAILS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "get",
        endpoint: `admin/dashboard/organisation-revenue-stats?start_date=${start_date}&end_date=${end_date}`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCH_ORG_REVENUE_STATS_DETAILS_SUCCESS,
          isFetching: false,
          details: res?.data,
        });
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: FETCH_ORG_REVENUE_STATS_DETAILS_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ORG_REVENUE_STATS_DETAILS_FAILURE,
        isFetching: false,
      });
    }
  };

export const adminLogin = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ATTEMPT_TO_ADMIN_LOGIN,
      isLoggingIn: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `admin/auth/login`,
      body: { admin: body },
    });

    if (res?.status === "Success") {
      const { token } = res?.data;
      window.localStorage.setItem(APP_TOKEN, token);
      window.localStorage.setItem(USER_TYPE, "admin");
      window.localStorage.setItem(USER_ID, res?.data?.admin?._id);
      const tempUser = jwt_decode(token);
      dispatch(setAdmin(tempUser));
      dispatch(setUserType("admin"));
      dispatch(setAuthState(AUTHENTICATED));

      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        isLoggingIn: false,
        isAuthenticated: true,
      });

      showNotification("success", "Welkom bij het beheerdersdashboard van MosqApp.");

      window.location = "/admin/dashboard";
    } else {
      showNotification("error", "Referenties zijn niet geldig....!");
    }
  } catch (err) {
    dispatch({
      type: ADMIN_LOGIN_FAILED,
      isLoggingIn: false,
    });
  }
};

export const fetchApprovalList = (entityId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_APPROVAL_LIST,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `admin/dashboard/approval-list`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCHING_APPROVAL_LIST_SUCCESS,
        isFetching: false,
        list: res?.data?.list,
        count: res?.data?.count,
      });
    } else {
      showNotification("error", "Er is iets misgegaan..");
      dispatch({
        type: FETCHING_APPROVAL_LIST_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCHING_APPROVAL_LIST_FAILURE,
      isFetching: false,
    });
  }
};

export const entityApprove =
  (entityId, approvalId,details = null) => async (dispatch, getState) => {
    try {
      let mosqueDetails = getState()?.adminReducer?.mosqueDetails;
      let updatedMosqueDetails = {
      ...mosqueDetails,
    };
    console.log(updatedMosqueDetails, details);
      const res = await callApiV2({
        method: "post",
        endpoint: `onboarding/entity/${entityId}/approval/${approvalId}/approve`,
        body: updatedMosqueDetails ,
      });
      if (res?.status === "Success") {
        showNotification("success", "Entiteit succesvol goedgekeurd.");
        dispatch({
          type: MOSQUE_APPROVAL_STATUS,
          approvalId,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
    }
  };

export const entityDecline =
  (approvalId) => async (dispatch, getState) => {
    try {
      const res = await callApiV2({
        method: "post",
        endpoint: `admin/dashboard/approval/decline/${approvalId}`,
      });
      if (res?.status === "Success") {
        showNotification("success", "Entity declined.");
        dispatch({
          type: MOSQUE_DECLINE_STATUS,
          approvalId,
        });
        window.location.href = "/admin/mosque/add";
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
    }
  };
